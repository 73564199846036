import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import EventPageStore, { EventType } from './store';
import { observer } from 'mobx-react';
import { Box, Button, CircularProgress, Grid, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { getEventPage, updateEventCredits } from '../../api';
import ImagesUploader from '../../components/ImagesUploader';
import ExistingImagesSection from './ExistingImagesSection';
import AnalyticsSection from './AnalyticsSetion';
import EventLinksSection from './EventLinksSection';
import ActionButtonsSection from './ActionButtonsSection';
import DeleteEventModal from './DeleteEventModal';
import ScanEventImagesModal from './ActiveEventPage/ScanEventImagesModal';
import StartEventModal from './NonActiveEventPage/StartEventModal';
import PracticipentsFilePicker from './NonActiveEventPage/PracticipentsFilePicker';
import { EventMode } from '../../models/IEvent';
import BrandImagesPickers from './BrandImagesPickers';
import { IEventPage } from '../../models/IEventsPage';
import CopyLinkCard from '../../components/CopyLinkCard';
import { LoadingButton } from '@mui/lab';

const EventPage: React.FC<{}> = observer(({ }) => {
    const { eventId } = useParams();
    const [isSavePhotographersCreditsLoading, setIsSavePhotographersCreditsLoading] = useState(false);
    if (!eventId) throw new Error("Event id is missing");
    const { data: eventPage, error, isLoading, } = useQuery(['eventPage', eventId], () => getEventPage(eventId), { refetchOnWindowFocus: false, });
    const eventPageData = eventPage as IEventPage;

    const handleSavePhotographersCredits = async () => {
        try {
            setIsSavePhotographersCreditsLoading(true)
            await updateEventCredits(eventId, [
                { label: "צלם מגנטים", value: EventPageStore.magnetPhotographer || "", link: EventPageStore.igLinkMagnetPhotographer },
                { label: "צלם סטילס", value: EventPageStore.stilsPhotographer || "", link: EventPageStore.igLinkStilsPhotographer }
            ])
        } catch (err) {
            console.log(err);
        } finally {
            setIsSavePhotographersCreditsLoading(false);
        }
    }

    useEffect(() => {
        EventPageStore.setFewImagesFromEvent(eventPageData?.fewImagesFromTheEvent || []);
        EventPageStore.setImagesCount(eventPageData?.imagesCount || 0);
    }, [eventPage])

    useEffect(() => {
        EventPageStore.setEventId(eventId);
    }, [eventId]);

    useEffect(() => {
        return () => {
            EventPageStore.clear();
        }
    }, []);

    useEffect(() => {
        EventPageStore.setIsEventActive(eventPageData?.isActive ?? false);
        EventPageStore.setEventName(eventPageData?.name ?? '');
        EventPageStore.setMagnetPhotographer(eventPageData?.credits?.[0].value || "");
        EventPageStore.setStilsPhotographer(eventPageData?.credits?.[1].value || "");
        EventPageStore.setIgLinkMagnetPhotographer(eventPageData?.credits?.[0].link || "");
        EventPageStore.setIgLinkStilsPhotographer(eventPageData?.credits?.[1].link || "");
    }, [eventPage]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>משהו השתבש אנא נסה שנית מאוחר יותר...</div>;
    }

    const event = eventPage as IEventPage & { imagesLimit: number };
    const uploadedImages = EventPageStore.imagesCount;
    const imagesLimit = event?.imagesLimit;
    const uploadProgress = Math.round((uploadedImages / imagesLimit) * 100);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw' // ensures the Box takes full width of the viewport
        }}>
            <Box sx={{ maxWidth: '800px', width: '100%', textAlign: 'start' }}>
                <Typography variant="h4" fontWeight="bold" mb={1}>
                    האירוע של {event.name}
                </Typography>
                {
                    event.isActive &&
                    <Typography variant="body1" color="grey" gutterBottom>
                        האירוע מתקיים ברגעים אלו...
                    </Typography>
                }

                <LinearProgress variant="determinate" value={uploadProgress} sx={{ width: '100%', height: '10px', marginBottom: '5px', borderRadius: "16px" }} />
                <Typography variant="body2" color="grey" textAlign="start" gutterBottom>
                    {uploadedImages}/{imagesLimit} תמונות הועלו
                </Typography>
                <AnalyticsSection
                    activePracticipantsCount={event.activePracticipantsCount}
                    allPracticipantsCount={event.allPracticipantsCount}
                    uploadedImagesCount={event.imagesCount} />
                <Box mt={3}>
                    <ImagesUploader />
                </Box>
                {
                    !EventPageStore.isEventActive &&
                    <>
                        <Box mt={2}>
                            <Typography variant="body2" color="grey" textAlign="start" gutterBottom> סוג אירוע: </Typography>
                            <Select
                                dir="rtl"
                                defaultValue="wedding"
                                sx={{ width: "100%", '.MuiOutlinedInput-notchedOutline': { borderRadius: '16px' } }}
                                onChange={(e) => EventPageStore.setEventType(e.target.value as EventType)}
                            >
                                <MenuItem value="wedding" dir="rtl">חתונה</MenuItem>
                                <MenuItem value="hina" dir="rtl">חינה</MenuItem>
                                <MenuItem value="conference" dir="rtl">כנס</MenuItem>
                                <MenuItem value="party" dir="rtl">מסיבה</MenuItem>
                            </Select>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body2" color="grey" textAlign="start" gutterBottom> שיטת הפצה: </Typography>
                            <Select
                                dir="rtl"
                                defaultValue="whatsapp"
                                sx={{ width: "100%", '.MuiOutlinedInput-notchedOutline': { borderRadius: '16px' } }}
                                onChange={(e) => EventPageStore.setEventMode(e.target.value as EventMode)}
                            >
                                <MenuItem value="whatsapp" dir="rtl">וואצאפ בלבד</MenuItem>
                                <MenuItem value="hybrid" dir="rtl">וואצאפ + מסך האלבום שלי</MenuItem>
                                <MenuItem value="messagesClientUi" dir="rtl">web mode</MenuItem>
                            </Select>
                        </Box>
                    </>
                }
                <Box>
                    {!EventPageStore.isEventActive && <PracticipentsFilePicker />}
                </Box>
                <BrandImagesPickers backgroundImageUrl={eventPageData.backgroundImageUrl} mainImageUrl={eventPageData.mainPageUrl} />
                <Box mt={5}>
                    <ExistingImagesSection images={EventPageStore.fewImagesFromEvent || []} />
                </Box>
                <Box mt={3}>
                    <Typography variant="h6" gutterBottom>
                        פרטי הצלמים
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="צלם מגנטים"
                                variant="outlined"
                                placeholder="matanlevi"
                                defaultValue={EventPageStore.magnetPhotographer}
                                value={EventPageStore.magnetPhotographer}
                                onChange={(e) => EventPageStore.setMagnetPhotographer(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="קישור לאינסטגרם"
                                variant="outlined"
                                defaultValue={EventPageStore.igLinkMagnetPhotographer}
                                value={EventPageStore.igLinkMagnetPhotographer}
                                onChange={(e) => EventPageStore.setIgLinkMagnetPhotographer(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} gap={1}>
                            <TextField
                                fullWidth
                                label="צלם סטילס"
                                variant="outlined"
                                placeholder="yairmoshe"
                                defaultValue={EventPageStore.stilsPhotographer}
                                value={EventPageStore.stilsPhotographer}
                                onChange={(e) => EventPageStore.setStilsPhotographer(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="קישור לאינסטגרם"
                                variant="outlined"
                                defaultValue={EventPageStore.igLinkStilsPhotographer}
                                value={EventPageStore.igLinkStilsPhotographer}
                                onChange={(e) => EventPageStore.setIgLinkStilsPhotographer(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={isSavePhotographersCreditsLoading}
                                variant="contained"
                                disabled={!event.isActive}
                                color="primary"
                                onClick={handleSavePhotographersCredits}
                                fullWidth
                            >
                                שמור
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={2}>
                    <CopyLinkCard
                        link={`
קישור להעלאת תמונות : ${event.photographerUploadLink}
קוד גישה להעלאת תמונות : ${event.photographerUploadLinkCode}
`}
                        label="העתק קישור העלאת תמונות לאירוע" />
                    <CopyLinkCard
                        link={`${window.location.host}/myEventAlbum/selfie/${eventId}`}
                        label="העתק קישור לצפייה במסך סלפי" />
                    <EventLinksSection joinEventLinkUrl={event.joinEventLink} />
                    <ActionButtonsSection />
                </Box>
            </Box>
            {EventPageStore.deleteEventModalOpen && <DeleteEventModal />}
            {EventPageStore.scanEventModalOpen && <ScanEventImagesModal />}
            {EventPageStore.startEventModalOpen && < StartEventModal />}
        </Box>
    );
});

export default EventPage;
